<template>
    <section class="error container">
        <div class="info">
            <h1>
                Error
                <span
                    :class="
                        `code ${
                            colors[Math.floor(Math.random() * colors.length)]
                        }`
                    "
                    >404</span
                >
            </h1>
            <h2 class="sub-title">
                {{ $t("error.not-found") }}
            </h2>
            <Link :back="true" path="/" :title="$t('error.return')" />
        </div>
        <div class="graphic">
            <div class="graphic-svg"></div>
        </div>
    </section>
</template>

<script>
import Link from "@/components/Link";

export default {
    title: "Page not found",
    lang: process.env.VUE_APP_I18N_LOCALE,
    components: {
        Link
    },
    data: function() {
        return {
            colors: [
                "red-bg",
                "maroon-bg",
                "blue-bg",
                "baby-blue-bg",
                "grey-bg"
            ]
        };
    },
    metaInfo() {
        return {
            title: `${
                this.$root.$i18n.locale === "en" ? "Not found" : "Niet gevonden"
            }`,
            htmlAttrs: {
                lang: this.$root.$i18n.locale
            },
            meta: [
                // Primary Meta Tags
                {
                    name: "title",
                    content: "Not found - Renders by Julie"
                },
                {
                    name: "description",
                    content: "Pagina is niet gevonden"
                },
                // Secondary Meta Tags
                {
                    name: "author",
                    content: "Julie Lejour"
                },
                {
                    name: "robots",
                    content: "noindex, nofollow"
                },
                {
                    "http-equiv": "Content-Type",
                    content: "text/html; charset=utf-8"
                },
                {
                    name: "language",
                    content: this.$root.$i18n.locale === "en" ? "EN" : "NL"
                },
                {
                    name: "publisher",
                    content: "Renders by Julie"
                },
                {
                    name: "copyright",
                    content: `Copyright ${new Date().getFullYear()} Renders by Julie - All Rights Reserved`
                }
            ]
        };
    }
};
</script>

<style lang="scss">
.error {
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    place-items: center;
    margin: 0 auto !important;

    h1 {
        font-size: 48px;
        letter-spacing: 0.1em;
    }

    h2 {
        font-size: 36px;
        margin-bottom: 3rem;
    }

    .code {
        padding: 0 1rem;
    }

    .graphic {
        opacity: 0.7;

        .graphic-svg {
            background-image: url("../../assets/images/logo.svg");
            background-size: cover;
            height: 500px;
            width: 400px;
            bottom: 3rem;
            background-size: auto;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    @media (max-width: 1000px) {
        padding: 0;
        grid-template-columns: repeat(1, 1fr);

        .graphic {
            z-index: -1;
            position: absolute;
            width: 100%;
            height: 100%;
            display: grid;
            place-items: center;
            opacity: 0.1;
        }
    }

    @media (max-width: 600px) {
        .info {
            text-align: center;
        }

        .inline-link {
            display: flex;
            justify-content: center;
        }

        h1 {
            font-size: 36px;
        }

        h2 {
            font-size: 24px;
        }

        .graphic-svg {
            height: 90% !important;
            width: 90% !important;
        }
    }
}
</style>
